import React from 'react';
import { View, Text, Link, Button, HeaderText } from '../components/common';
import Layout from '../components/layout';
import Content from '../components/content';
import CenteredMessage from '../components/CenteredMessage';
import { colors } from '../style';

function isOSX() {
  var ua = window.navigator.userAgent;
  var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  var webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
}

function isWindows() {
  return navigator.platform.indexOf('Win') > -1;
}

let appStoreUrl =
  'https://itunes.apple.com/us/app/actual-budget-your-finances/id1444818585';

function cameFromApp() {
  return !!location.search.match(/fromapp/);
}

class DownloadPage extends React.Component {
  state = { fromApp: false, windows: false };

  componentDidMount() {
    // If the user is coming from the app, they are already running it
    // and don't need to download it again
    if (cameFromApp()) {
      this.setState({ fromApp: true });
      return;
    }

    if (isOSX()) {
      // Take them to the app store
      window.location.href = appStoreUrl;
    } else {
      setTimeout(() => {
        if (window.location.hash !== '#downloaded') {
          window.location.href = 'https://download.actualbudget.com/download';
          window.location.hash = '#downloaded';
        }
      }, 1000);
    }

    if (isWindows()) {
      this.setState({ windows: true });
    }
  }

  renderWindowsWarning() {
    return (
      <View
        style={{
          backgroundColor: colors.y10,
          fontSize: '.85em',
          textAlign: 'left',
          padding: '10px 14px',
          borderRadius: 6,
          marginTop: 20
        }}
      >
        <Text>
          <strong>Windows users:</strong> you may see a SmartScreen warning when
          opening the app. We recently renewed the certificate and it{"'"}s
          building up trust again. This should not last for long.
        </Text>
      </View>
    );
  }

  renderSubscribed() {
    let { fromApp, windows } = this.state;

    return (
      <React.Fragment>
        <HeaderText
          style={{ alignSelf: 'center', marginTop: 50, marginBottom: 30 }}
        >
          Thanks for subscribing!
        </HeaderText>
        <CenteredMessage className="text-lg" style={{ marginBottom: 30 }}>
          {fromApp ? (
            <span>Go back to the app to log in with your email.</span>
          ) : (
            <>
              <span>
                Your download will begin now. Check your email for a code to
                activate your plan within the app.
              </span>

              {windows && this.renderWindowsWarning()}
            </>
          )}
        </CenteredMessage>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Layout>
        <Content>
          {this.renderSubscribed()}
          <CenteredMessage>
            <View>
              <Text className="text-gray-700">
                Contact{' '}
                <Link external to="mailto:help@actualbudget.com">
                  help@actualbudget.com
                </Link>{' '}
                for support
              </Text>
            </View>

            <View className="mt-4">
              <Text>
                Join the community:{' '}
                <Link external to="https://slack.actualbudget.com">
                  Slack
                </Link>{' '}
                and{' '}
                <Link external to="https://reddit.com/r/actualbudget">
                  Reddit
                </Link>
                <View style={{ marginTop: 20 }}>
                  <Text style={{ fontSize: 13, color: colors.n4 }}>
                    Download other platforms{' '}
                    <Link to="/download-platforms/">here</Link>.
                  </Text>
                </View>
              </Text>
            </View>
          </CenteredMessage>
        </Content>
      </Layout>
    );
  }
}

export default DownloadPage;
